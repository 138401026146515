<template>
    <h3>App版本说明</h3>
    <h4>V1.0.39</h4>
    <ul>
        <li>切换正式域名</li>
        <li>优化用户体验</li>
    </ul>
    <h4>V1.0.37</h4>
    <ul>
        <li>优化运动数据页面</li>
        <li>优化用户体验</li>
    </ul>
    <h4>V1.0.36</h4>
    <ul>
        <li>优化运动显示页面</li>
        <li>优化数据显示页面</li>
        <li>优化用户信息默认头像</li>
    </ul>
    <h4>V1.0.35</h4>
    <ul>
        <li>优化总步数数据同步逻辑</li>
        <li>优化数据页面显示</li>
    </ul>
    <h4>V1.0.34</h4>
    <ul>
        <li>优化数据显示页面</li>
        <li>优化蓝牙连接逻辑</li>
    </ul>
    <h4>V1.0.33</h4>
    <ul>
        <li>优化数据刷新逻辑</li>
        <li>优化启动页面显示效果</li>
    </ul>
    <h4>V1.0.31</h4>
    <ul>
        <li>优化数据页面展示效果</li>
        <li>优化蓝牙连接功能</li>
    </ul>
    <h4>V1.0.29</h4>
    <ul>
        <li>修改蓝牙自动连接BUG</li>
        <li>适配手表多机型</li>
        <li>优化首页界面</li>
    </ul>
    <h4>V1.0.26</h4>
    <ul>
        <li>优化蓝牙自动连接</li>
        <li>修改数据上传相关逻辑</li>
        <li>完善所有数据统计列表</li>
    </ul>
    <h4>V1.0.21</h4>
    <ul>
        <li>优化蓝牙连接</li>
        <li>优化数据传输方式</li>
        <li>完善历史数据列表</li>
    </ul>
    <h4>V1.0.13</h4>
    <ul>
        <li>优化导航栏显示效果</li>
        <li>优化权限获取方式</li>
        <li>优化蓝牙连接逻辑</li>
        <li>完善历史数据列表展示</li>
    </ul>
    <h4>V1.0.0</h4>
    <ul>
        <li>初始版本</li>
        <li>完成用户相关功能</li>
        <li>完成蓝牙连接相关功能</li>
        <li>完成首页数据显示</li>
    </ul>
</template>

<script>
    export default {
        name: 'App',
        components: {}
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        color: #2c3e50;
        margin-top: 20px;
    }
</style>
